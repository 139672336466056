<template>
<v-row class="page-breadcrumb">
        <v-col cols="12" sm="12" class="pa-0">
          <v-card class="px-0 custom-shaodow">
            <v-toolbar dense flat class="transparent pa-0" height="50px">
              <v-toolbar-title class="pt-3">
                <span class="text--primary">{{ title }}</span>
              </v-toolbar-title>
              <v-toolbar-title
                class="toobar-extension transparent pb-3"
                slot="extension"
              >
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                  <template v-slot:divider v-if="breadcrumbs">
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>

                <slot></slot>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card>
        </v-col>

         <!-- <v-col cols="12" sm="6" class="pa-0">
          <v-card class="px-0 custom-shaodow d-flex justify-end">
            <v-toolbar dense flat class="transparent pa-0" height="98px">
              <v-row>
                <v-col cols="12" sm="12" class="d-flex justify-end no-padding">
                  <v-toolbar-title class="pt-0">
                    <v-btn
                      elevation="2"
                      small
                      color="rgb(11, 76, 50)"
                      class="padding-v"
                      v-bind="attrs"
                      v-on="on"
                      @click="openPeriod"
                      ><h6 class="whiteColor">Cambiar</h6></v-btn
                    >
                    <span class="text--primary right-padding"
                      >Periodo de Trabajo</span
                    >
                  </v-toolbar-title>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Año:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6> {{year!=='No válido'?'':yearSelected}} {{ year }}</h6>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Mes:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6> {{month !=='No válido'?'':monthSelected}} {{ month }}</h6>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Periodo Cerrado:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6>{{ closed }}</h6>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Recibos Generados:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6>{{ generated }}</h6>
                </v-col>
              </v-row>
            </v-toolbar>
          </v-card>
        </v-col>  -->
      </v-row>
  <!-- <v-dialog v-model="dialog"  max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-row class="page-breadcrumb">
        <v-col cols="12" sm="12" class="pa-0">
          <v-card class="px-0 custom-shaodow">
            <v-toolbar dense flat class="transparent pa-0" height="50px">
              <v-toolbar-title class="pt-3">
                <span class="text--primary">{{ title }}</span>
              </v-toolbar-title>
              <v-toolbar-title
                class="toobar-extension transparent pb-3"
                slot="extension"
              >
                <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                  <template v-slot:divider v-if="breadcrumbs">
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>

                <slot></slot>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card>
        </v-col>

         <v-col cols="12" sm="6" class="pa-0">
          <v-card class="px-0 custom-shaodow d-flex justify-end">
            <v-toolbar dense flat class="transparent pa-0" height="98px">
              <v-row>
                <v-col cols="12" sm="12" class="d-flex justify-end no-padding">
                  <v-toolbar-title class="pt-0">
                    <v-btn
                      elevation="2"
                      small
                      color="rgb(11, 76, 50)"
                      class="padding-v"
                      v-bind="attrs"
                      v-on="on"
                      @click="openPeriod"
                      ><h6 class="whiteColor">Cambiar</h6></v-btn
                    >
                    <span class="text--primary right-padding"
                      >Periodo de Trabajo</span
                    >
                  </v-toolbar-title>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Año:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6> {{year!=='No válido'?'':yearSelected}} {{ year }}</h6>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Mes:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6> {{month !=='No válido'?'':monthSelected}} {{ month }}</h6>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Periodo Cerrado:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6>{{ closed }}</h6>
                </v-col>
                <v-col cols="9" sm="9" class="d-flex justify-end no-padding">
                  <h6>Recibos Generados:</h6>
                </v-col>
                <v-col cols="3" sm="3" class="d-flex justify-init left-padding">
                  <h6>{{ generated }}</h6>
                </v-col>
              </v-row>
            </v-toolbar>
          </v-card>
        </v-col> 
      </v-row>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">Cambio de Periodo</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" sm="6" class="nopaddingtopbottom">
                <v-row>
                  <v-col cols="12" sm="12" class="nopaddingtopbottom">
                    <span class="text-h8">Año</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" class="nopaddingtopbottom">
                    <v-select
                      v-model="yearValue"
                      :hint="`${yearValue.description}`"
                      :items="itemsYear"
                      label="Seleccione Año"
                      item-text="description"
                      item-value="code"
                      class="nopadding"
                      persistent-hint
                      return-object
                      single-line
                      required
                      v-on:change="validatePeriod()"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6" class="nopaddingtopbottom">
                <v-row>
                  <v-col cols="12" sm="12" class="nopaddingtopbottom">
                    <span class="text-h8">Mes</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" class="nopaddingtopbottom">
                    <v-select
                      v-model="monthValue"
                      :hint="`${monthValue.description}`"
                      :items="itemsMonth"
                      label="Seleccione Mes"
                      item-text="description"
                      item-value="code"
                      class="nopadding"
                      persistent-hint
                      return-object
                      single-line
                      required
                      v-on:change="validatePeriod()"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>*Estado: {{ this.state }} </small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false">
          Cancelar
        </v-btn>
        <v-btn color="blue darken-1" text @click="change"> Seleccionar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> -->
</template>
<script>
export default {
  name: "BaseBreadcrumb",
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
  },
  data: () => ({
    elementVisible: false,
    dialog: false,
    valid: true,
  }),
  watch: {
  },
  computed: {
  },
  methods: {
    
  },
  updated() {
  },
  created() {
  },
  mounted(){
    
  }
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -20px -25px 0px -25px;
}
.no-padding {
  padding: 0px !important;
}
.left-padding {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.right-padding {
  padding-right: 10px !important;
}
.padding-v {
  padding: 0 10px !important;
}
.whiteColor {
  color: white !important;
}
.nopaddingtopbottom {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
}
</style>