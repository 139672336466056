<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="6" class="pa-0">
      <v-card class="px-0 custom-shaodow">
        <v-toolbar dense flat class="transparent pa-0" height="50px">
          <v-toolbar-title class="pt-3">
            <span class="text--primary">{{ title }}</span>
          </v-toolbar-title>
          <v-toolbar-title
            class="toobar-extension transparent pb-3"
            slot="extension"
          >
            <v-breadcrumbs :items="breadcrumbs" class="pa-0">
              <template v-slot:divider v-if="breadcrumbs">
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>

            <slot></slot>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BaseBreadcrumb",
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
  },
  data: () => ({
    elementVisible: false,
    dialog: false,
    valid: true,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
  },
  methods: {
  },
  updated() {},
  created() {
  },
  mounted() {},
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -20px -25px 0px -25px;
}
.no-padding {
  padding: 0px !important;
}
.left-padding {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.right-padding {
  padding-right: 10px !important;
}
.padding-v {
  padding: 0 10px !important;
}
.whiteColor {
  color: white !important;
}
.nopaddingtopbottom {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
}
</style>