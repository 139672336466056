import Vue from 'vue'
import Vuex from 'vuex'

import { axiosHttps } from '../resources/apiGoldCar'
//import SidebarItems from "../layouts/full-layout/sidebar/SidebarItems";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white',
        SidebarBg: '',
        token: '',
        user: null,
        name: '',
        type: '',
        email_user: '',
        permisos: [],
        views: [],
        consultant_id: '',
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload
        },
        async SET_USER_LOGIN(state, payload) {
            localStorage.setItem('token', payload.data.token);
            localStorage.setItem('consultant_id', payload.data.consultant_id);
            state.token = payload.data.token;
            state.consultant_id = payload.data.consultant_id;
            try {
                const resp = await axiosHttps.get("auth/user/");
                state.user = resp.data;
                state.name = resp.data.first_name;
                state.type = resp.data.alias;
                state.email_user = resp.data.email;
                localStorage.setItem('user', JSON.stringify(state.user));
                localStorage.setItem('name', state.name);
                localStorage.setItem('type', state.type);
                localStorage.setItem('email_user', state.email);

                const res = await axiosHttps.get("auth/permissions/");
                state.permisos = res.data;
                localStorage.setItem('permisos', JSON.stringify(state.permisos));

                //Agregamos los permisos del usuario
                let sidebar_aux = [];
                let modules = ["user", "rol", "business", "customer", "contact", "contact_type", "supplier", "brand", "model", "state", "operator", "chip", "avl", "avlfirmware", "platform", "entry", "service_type", "price", "transmission_type"
                    , "equipment_mode", "issued_documents", "attached_documents", "consulting", "support", "unit", "accessory", "accessoriesmodels", "observation", "kit", "pack", "quotation", "serie", "sale", "autodetraction"
                    , "txt", "bank", "saleinvoice", "bankaccount", "pettycash", "bankloan", "creditnote", "purchase", "txtpurchase", "my_profile", "payroll", "summary_loan", "calendar_loan", "worker", "pensionfund", "afp"
                    , "payrollsetting", "accountingpayroll", "salarybonus", "feereceipt", "payrollpayment", "holidays", "category", "productcode", "warehousetype", "warehouse", "incomeguide", "outcomeguide", "unitlogistic", "chipplan", "accountantguide"
                    , "wagesummary", "schedule", "mails", "certificates","technical_area"];

                let admChildren = [];
                let dataChildren = [];
                let logChildren = [];
                let sellChildren = [];
                let accountingChildren = [];
                let pettycashChildren = [];
                let mantChildren = [];
                let myProfileChildren = [];
                let payrollChildren = [];
                let mailChildren = [];
                let certificatesChildren= [];
                let technicalAreaChildren = [];
                //Mantenimiento
                let mantGeneralChildren = [];
                let mantAvlChildren = [];
                let mantUnitChildren = [];
                let mantSaleChildren = [];
                //Subniveles Contabilidad
                let tsellChildren = [];
                let tpurchaseChildren = [];
                let tloanChildren = [];
                let taccountantguideChildren = [];
                let scheduleChildren = [];

                for (let module of modules) {
                    let estado = false;

                    //Modulo administracion
                    if (module === 'user') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_user") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            admChildren.push(
                                {
                                    icon: "mdi-account-multiple",
                                    title: 'Usuarios',
                                    to: 'user',
                                }
                            )
                        }
                    }
                    if (module === 'rol') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_group") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            admChildren.push(
                                {
                                    icon: 'mdi-arrow-decision',
                                    title: 'Roles',
                                    to: 'rol',
                                }
                            )
                        }
                    }
                    if (module === 'business') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_enterprise") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            admChildren.push(
                                {
                                    icon: 'mdi-domain',
                                    title: 'Nuestras Empresas',
                                    to: 'business',
                                }
                            )
                        }
                    }

                    //Modulo data
                    if (module === 'customer') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_client") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            dataChildren.push(
                                {
                                    icon: 'mdi-face-agent',
                                    title: 'Clientes',
                                    to: 'customer',
                                }
                            )
                        }
                    }
                    if (module === 'contact') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_contact") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            dataChildren.push(
                                {
                                    icon: 'mdi-account-box',
                                    title: 'Contactos',
                                    to: 'contact',
                                }
                            )
                        }
                    }
                    if (module === 'unit') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_unit") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            dataChildren.push(
                                {
                                    icon: "mdi-car-estate",
                                    title: 'Unidades',
                                    to: 'unit',
                                }
                            )
                        }
                    }
                    if (module === 'bank') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_bank") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            dataChildren.push(
                                {
                                    icon: 'mdi-bank',
                                    title: 'Bancos',
                                    to: 'bank',
                                }
                            )
                        }
                    }
                    if (module === 'bankaccount') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_bankaccount") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            dataChildren.push(
                                {
                                    icon: 'mdi-piggy-bank-outline',
                                    title: 'Cuentas Bancarias',
                                    to: 'bankaccount',
                                }
                            )
                        }
                    }

                    //Modulo logistica
                    if (module === 'operator') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_operator") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-credit-card-chip',
                                    title: 'Operador',
                                    to: 'operatorchip',
                                }
                            )
                        }
                    }
                    if (module === 'chip') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_chip") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-integrated-circuit-chip',
                                    title: 'Chip',
                                    to: 'simcard',
                                }
                            )
                        }
                    }
                    if (module === 'avl') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_avl") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: "mdi-crosshairs-gps",
                                    title: 'GPS',
                                    to: 'avlgps',
                                }
                            )
                        }
                    }
                    if (module === 'avlfirmware') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_firmware") {
                                estado = true;
                            }

                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-crosshairs-gps',
                                    title: 'Firmware',
                                    to: 'avlfirmware',
                                }
                            )
                        }
                    }
                    if (module === 'accessory') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_accessory") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: "mdi-seat",
                                    title: 'Accesorios',
                                    to: 'accessory',
                                }
                            )
                        }
                    }
                    if (module === 'accessoriesmodels') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_accessory_model") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: "mdi-seat",
                                    title: 'Modelo de Accesorios',
                                    to: 'accessorymodel',
                                }
                            )
                        }
                    }
                    if (module === 'category') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_category") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Categoria',
                                    to: 'category',
                                }
                            )
                        }
                    }
                    if (module === 'productcode') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_productcode") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-barcode',
                                    title: 'Código Producto',
                                    to: 'productcode',
                                }
                            )
                        }
                    }

                    if (module === 'warehousetype') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_warehousetype") {
                                estado = true;
                            }

                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-warehouse',
                                    title: 'Tipo de almacén',
                                    to: 'warehousetype',
                                }
                            )
                        }
                    }

                    if (module === 'warehouse') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_warehouse") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-warehouse',
                                    title: 'Almacen',
                                    to: 'warehouse',
                                }
                            )
                        }
                    }
                    if (module === 'incomeguide') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_guide") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-wardrobe',
                                    title: 'Guia Interna Entrada',
                                    to: 'incomeguide',
                                }
                            )
                        }
                    }
                    if (module === 'outcomeguide') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_guide") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-wardrobe-outline',
                                    title: 'Guia Interna Salida',
                                    to: 'outcomeguide',
                                }
                            )
                        }
                    }
                    if (module === 'unitlogistic') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_unit_logistic") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-car-estate',
                                    title: 'Unidades',
                                    to: 'unit',
                                }
                            )
                        }
                    }
                    if (module === 'chipplan') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_plan") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            logChildren.push(
                                {
                                    icon: 'mdi-floor-plan',
                                    title: 'Plan Chip',
                                    to: 'chipplan',
                                }
                            )
                        }
                    }
                    //Modulo Ventas
                    if (module === 'quotation') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_quotation") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            sellChildren.push(
                                {
                                    icon: "mdi-pencil",
                                    title: 'Cotización',
                                    to: 'quotation',
                                }
                            )
                        }
                    }
                    
                    if (module === 'sale') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_sellguide") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            sellChildren.push(
                                {
                                    icon: "mdi-sale",
                                    title: 'Ventas',
                                    to: 'sale',
                                }
                            )
                        }
                    }
                    if (module === 'saleinvoice') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_sell") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            sellChildren.push(
                                {
                                    icon: "mdi-text-box",
                                    title: 'Facturas Ventas',
                                    to: 'saleinvoice',
                                }
                            )
                        }
                    }
                    if (module === 'bankaccount') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_bankaccount") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            sellChildren.push(
                                {
                                    icon: 'mdi-piggy-bank-outline',
                                    title: 'Pagos',
                                    to: 'payments',
                                }
                            )
                        }
                    }
                    if (module === 'creditnote') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_creditnote") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            sellChildren.push(
                                {
                                    icon: "mdi-text-box",
                                    title: 'Notas de Crédito',
                                    to: 'creditnote',
                                }
                            )
                        }
                    }
                    //Contabilidad
                    if (module === 'autodetraction') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_detraction") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            tsellChildren.push(
                                {
                                    icon: "mdi-point-of-sale",
                                    title: 'Autodetracción',
                                    to: 'autodetraction',
                                }
                            )
                        }
                    }
                    if (module === 'txt') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_autodetractiontxtfile") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            tsellChildren.push(
                                {
                                    icon: "mdi-text-box",
                                    title: 'Generar TXT',
                                    to: 'txt',
                                }
                            )
                        }
                    }
                    if (module === 'purchase') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_detraction") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            tpurchaseChildren.push(
                                {
                                    icon: "mdi-point-of-sale",
                                    title: 'Detracción Compras',
                                    to: 'purchase',
                                }
                            )
                        }
                    }
                    if (module === 'txtpurchase') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_purchasedetractiontxtfile") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            tpurchaseChildren.push(
                                {
                                    icon: "mdi-text-box",
                                    title: 'Generar TXT',
                                    to: 'txtpurchase',
                                }
                            )
                        }
                    }
                    if (module === 'supplier') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_provider") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            accountingChildren.push(
                                {
                                    icon: 'mdi-charity',
                                    title: 'Proveedores',
                                    to: 'supplier',
                                }
                            )
                        }
                    }
                    if (module === 'bankloan') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_bankloan") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            tloanChildren.push(
                                {
                                    icon: "mdi-cash-multiple",
                                    title: 'Prestamos',
                                    to: 'bankloan',
                                }
                            )
                        }
                    }
                    if (module === 'summary_loan') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_bankloan") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            tloanChildren.push(
                                {
                                    icon: "mdi-text-long",
                                    title: 'Resumen',
                                    to: 'summarytable',
                                }
                            )
                        }
                    }
                    if (module === 'calendar_loan') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_bankloan") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            tloanChildren.push(
                                {
                                    icon: "mdi-calendar-blank",
                                    title: 'Calendario',
                                    to: 'summarycalendar',
                                }
                            )
                        }
                    }
                    if (module === 'accountantguide') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_accountantguide") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            taccountantguideChildren.push(
                                {
                                    icon: 'mdi-floor-plan',
                                    title: 'Guias de Entrada',
                                    to: 'accountantguide',
                                }
                            )
                        }
                    }
                    //Caja
                    if (module === 'pettycash') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_pettycash") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            pettycashChildren.push(
                                {
                                    icon: "mdi-cash-100",
                                    title: 'Cajas Chicas',
                                    to: 'pettycash',
                                }
                            )
                        }
                    }
                    //Mi Perfil
                    if (module === 'my_profile') {
                        myProfileChildren.push(
                            {
                                icon: "mdi-account",
                                title: 'Mi Perfil',
                                to: 'myprofile',
                            }
                        )
                    }
                    //Planilla
                    if (module === 'worker') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_worker") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Trabajadores',
                                    to: 'worker',
                                }
                            )
                        }
                    }
                    if (module === 'pensionfund') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_pensionfund") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Fondo de Pension',
                                    to: 'pension',
                                }
                            )
                        }
                    }
                    if (module === 'afp') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_afp") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Afp',
                                    to: 'afp',
                                }
                            )
                        }
                    }
                    if (module === 'payrollsetting') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_payrollsetting") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Configuracion de Planilla',
                                    to: 'setting',
                                }
                            )
                        }
                    }
                    if (module === 'accountingpayroll') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_accountingpayroll") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Planillas Contables',
                                    to: 'accounting',
                                }
                            )
                        }
                    }
                    if (module === 'salarybonus') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_salarybonus") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Gratificaciones',
                                    to: 'salarybonuses',
                                }
                            )
                        }
                    }
                    if (module === 'feereceipt') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_feereceipt") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Recibos por Honorarios',
                                    to: 'feereceipts',
                                }
                            )
                        }
                    }
                    if (module === 'payrollpayment') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_payrollpayment") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Pagos de Planilla',
                                    to: 'payrollpayments',
                                }
                            )
                        }
                    }
                    if (module === 'holidays') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_holidays") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Vacaciones',
                                    to: 'holidays',
                                }
                            )
                        }
                    }
                    if (module === 'wagesummary') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_wagesummary") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            payrollChildren.push(
                                {
                                    icon: "mdi-file-document-multiple-outline",
                                    title: 'Reporte de Planillas',
                                    to: 'wagesummary',
                                }
                            )
                        }
                    }
                    //Programaciones
                    if (module === 'schedule') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_schedule") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            scheduleChildren.push(
                                {
                                    icon: "mdi-monitor",
                                    title: 'Programaciones',
                                    to: 'schedule',
                                }
                            )
                        }
                    }
                    if (module === 'schedule') {
                        
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_schedule") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            scheduleChildren.push(
                                {
                                    icon: "mdi-chart-histogram",
                                    title: 'Historial',
                                    to: 'historical',
                                }
                            )
                        }
                    }

                    // Mails
                    if (module === 'mails') {
                        const hasShowMailsPermission = state.permisos.some(permission => permission.codename === "show_tasktemplate");
                        const hasShowAdvisorPermission = state.permisos.some(permission => permission.codename === "show_saleadvisor");
                    
                        if (hasShowMailsPermission) {
                            mailChildren.push({
                                icon: "mdi-monitor",
                                title: 'Plantillas',
                                to: 'templates',
                            });
                        }
                    
                        if (hasShowAdvisorPermission) {
                            mailChildren.push({
                                icon: "mdi-chart-histogram",
                                title: 'Asesores',
                                to: 'advisors',
                            });
                        }
                    }
                    // Certificados de Vigencia
                    if (module === 'certificates') {
                        const hasShowCertificatesPermission = state.permisos.some(permission => permission.codename === "show_certificate");
                        //const hasShowCertificatesPermission =true;
                        if (hasShowCertificatesPermission) {
                            certificatesChildren.push({
                                icon: "mdi-certificate-outline",
                                title: 'Generar Certificado',
                                to: 'certificates',
                            });
                        }

                    }
                    // Area técnica
                    if (module === 'technical_area') {
                        const hasShowTechnicalAreaPermission = state.permisos.some(permission => permission.codename === "show_technicalarticle");
                        //const hasShowTechnicalAreaPermission = true;
                        
                        if (hasShowTechnicalAreaPermission) {
                            technicalAreaChildren.push({
                                icon: "mdi-file-document-outline",
                                title: 'Guías Técnicas',
                                to: 'technicalGuide',
                            });
                        }

                    }

                    //Mantenimiento
                    //2 General                       
                    if (module === 'contact_type') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_contacttype") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantGeneralChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Tipo Contactos',
                                    to: 'contacttype',
                                }
                            )
                        }
                    }
                    if (module === 'price') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_price") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantGeneralChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Precios',
                                    to: 'price',
                                }
                            )
                        }
                    }
                    if (module === 'observation') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_observation") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantGeneralChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Observaciones',
                                    to: 'observation',
                                }
                            )
                        }
                    }
                    //2 AVL
                    if (module === 'brand') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_brand") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantAvlChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Marca Generales',
                                    to: 'brandavl',
                                }
                            )
                        }
                    }
                    if (module === 'model') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_model") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantAvlChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Modelo GPS',
                                    to: 'modelavl',
                                }
                            )
                        }
                    }
                    //2 Unidad
                    if (module === 'platform') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_platform") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Plataforma',
                                    to: 'platform',
                                }
                            )
                        }
                    }
                    if (module === 'entry') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_workline") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Rubro',
                                    to: 'entry',
                                }
                            )
                        }
                    }
                    if (module === 'service_type') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_servicetype") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Tipos Servicio',
                                    to: 'servicetype',
                                }
                            )
                        }
                    }
                    if (module === 'transmission_type') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_retransmissiontype") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Tipos de Retransmisión',
                                    to: 'retransmissiontype',
                                }
                            )
                        }
                    }
                    if (module === 'equipment_mode') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_equipmentmode") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Modalidades de Equipo',
                                    to: 'equipmentmode',
                                }
                            )
                        }
                    }
                    if (module === 'issued_documents') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_issueddocument") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Documentos Emitidos',
                                    to: 'issueddocuments',
                                }
                            )
                        }
                    }
                    if (module === 'attached_documents') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_attacheddocument") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Documentos Anexos',
                                    to: 'attacheddocuments',
                                }
                            )
                        }
                    }
                    if (module === 'consulting') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_consultings") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Asesores',
                                    to: 'consulting',
                                }
                            )
                        }
                    }
                    if (module === 'support') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_supports") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Soportes',
                                    to: 'support',
                                }
                            )
                        }
                    }
                    //2 sale
                    if (module === 'kit') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_kit") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantSaleChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Bienes o Servicios',
                                    to: 'service',
                                }
                            )
                        }
                    }
                    if (module === 'pack') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_pack") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantSaleChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Kits',
                                    to: 'kit',
                                }
                            )
                        }
                    }
                    if (module === 'serie') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_serie") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantSaleChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Series',
                                    to: 'serie',
                                }
                            )
                        }
                    }
                    //2 Estados
                    if (module === 'state') {
                        for (let permission of state.permisos) {
                            if (permission.codename === "show_state") {
                                estado = true;
                            }
                        }
                        if (estado) {
                            mantAvlChildren.push(
                                {
                                    icon: "mdi-cogs",
                                    title: 'Estado GPS',
                                    to: 'stateavl',
                                }
                            );
                            mantGeneralChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Estado Chip',
                                    to: 'statechip',
                                }
                            );
                            /* mantGeneralChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Estado Servicio',
                                    to: 'stateservice',
                                }
                            ); */
                            mantUnitChildren.push(
                                {
                                    icon: 'mdi-cogs',
                                    title: 'Estado Vehículo',
                                    to: 'stateunit',
                                }
                            )
                        }
                    }
                }

                //Niveles
                //Nivel 2
                //Subniveles Contabilidad
                if (tsellChildren.length > 0) {
                    accountingChildren.push(
                        {
                            group: "/accounting",
                            model: false,
                            icon: "mdi-printer-pos-wrench-outline",
                            title: "Ventas",
                            children: tsellChildren,
                        }
                    );
                }
                if (tpurchaseChildren.length > 0) {
                    accountingChildren.push(
                        {
                            group: "/accounting",
                            model: false,
                            icon: "mdi-printer-pos-cog-outline",
                            title: "Compras",
                            children: tpurchaseChildren,
                        }
                    );
                }
                if (tloanChildren.length > 0) {
                    accountingChildren.push(
                        {
                            group: "/accounting",
                            model: false,
                            icon: "mdi-printer-pos-cog",
                            title: "Prestamos",
                            children: tloanChildren,
                        }
                    );
                }
                if (taccountantguideChildren.length > 0) {
                    accountingChildren.push(
                        {
                            group: "/accounting",
                            model: false,
                            icon: "mdi-printer-pos-cog",
                            title: "Kardex",
                            children: taccountantguideChildren,
                        }
                    );
                }
                //Subniveles Mantenimiento
                if (mantGeneralChildren.length > 0) {
                    mantChildren.push(
                        {
                            group: "/maintenance",
                            model: false,
                            icon: "mdi-wrench-clock",
                            title: "Mant. General",
                            children: mantGeneralChildren,
                        }
                    );
                }
                if (mantAvlChildren.length > 0) {
                    mantChildren.push(
                        {
                            group: "/maintenance",
                            model: false,
                            icon: "mdi-wrench-clock",
                            title: "Mant. GPS",
                            children: mantAvlChildren,
                        }
                    );
                }
                if (mantUnitChildren.length > 0) {
                    mantChildren.push(
                        {
                            group: "/maintenance",
                            model: false,
                            icon: "mdi-wrench-clock",
                            title: "Mant. Unidad",
                            children: mantUnitChildren,
                        }
                    );
                }
                if (mantSaleChildren.length > 0) {
                    mantChildren.push(
                        {
                            group: "/maintenance",
                            model: false,
                            icon: "mdi-wrench-clock",
                            title: "Mant. Ventas",
                            children: mantSaleChildren,
                        }
                    );
                }
                //Nivel 1
                if (admChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "/administration",
                            model: false,
                            icon: "mdi-application-edit",
                            title: "Administración",
                            children: admChildren,
                        },
                    )
                }
                if (dataChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "/data",
                            model: false,
                            icon: "mdi-database-cog",
                            title: "BD Datos",
                            children: dataChildren,
                        },
                    )
                }
                if (logChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "/logistics",
                            model: false,
                            icon: "mdi-archive-cog",
                            title: "Logística",
                            children: logChildren,
                        }
                    )
                }

                if (sellChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "/sell",
                            model: false,
                            icon: "mdi-store-settings",
                            title: "Ventas",
                            children: sellChildren,
                        }
                    );
                }
                if (accountingChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "/accounting",
                            model: false,
                            icon: "mdi-file-sign",
                            title: "Contabilidad",
                            children: accountingChildren,
                        }
                    );
                }
                if (pettycashChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "",
                            model: false,
                            icon: "mdi-cash",
                            title: "Caja",
                            children: pettycashChildren,
                        }
                    );
                }
                if (mantChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "/maintenance",
                            model: false,
                            icon: "mdi-wrench-clock",
                            title: "Mantenimiento",
                            children: mantChildren,
                        }
                    );
                }
                if (myProfileChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "",
                            model: false,
                            icon: "mdi-account",
                            title: "Mi Perfil",
                            children: myProfileChildren,
                        }
                    );
                }
                if (payrollChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "/payroll",
                            model: false,
                            icon: "mdi-file-account",
                            title: "Planilla",
                            children: payrollChildren,
                        }
                    );
                }
                if (scheduleChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "",
                            model: false,
                            icon: "mdi-monitor-dashboard",
                            title: "Programaciones",
                            children: scheduleChildren,
                        }
                    );
                }
                if (mailChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "",
                            model: false,
                            icon: "mdi-email",  // Cambiado a ícono de correo
                            title: "Correos",
                            children: mailChildren,
                        }
                    );
                }
                if (certificatesChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "",
                            model: false,
                            icon: "mdi-file-certificate",
                            title: "Certificados",
                            children: certificatesChildren,
                        }
                    );
                }
                if (technicalAreaChildren.length > 0) {
                    sidebar_aux.push(
                        {
                            group: "",
                            model: false,
                            icon: "mdi-hammer-wrench",
                            title: "Área Técnica",
                            children: technicalAreaChildren,
                        }
                    );
                }

                state.views = sidebar_aux;
                localStorage.setItem('views', JSON.stringify(state.views));

            } catch (error) {
                Vue.$toast.error(error + " || Error al obtener datos del usuario");
            }
        },
        async SET_USER(state, payload) {
            if (payload === '') {
                state.token = '';
                state.user = null;
                state.name = '';
                state.type = '';
                state.email_user = '';
                state.permisos = [];
                state.views = [];
                state.consultant_id = '';
            } else {
                state.token = payload.data.token;
                state.user = payload.data.user;
                state.name = payload.data.name;
                state.type = payload.data.type;
                state.email_user = payload.data.email_user;
                state.permisos = payload.data.permisos;
                state.views = payload.data.views;
                state.consultant_id = payload.data.consultant_id;
            }
        },
    },
    actions: {
        readUser({ commit }) {
            const token = localStorage.getItem('token')
            const user = localStorage.getItem('user')
            const name = localStorage.getItem('name')
            const type = localStorage.getItem('type')
            const email_user = localStorage.getItem('email_user')
            const permisos = localStorage.getItem('permisos')
            const views = localStorage.getItem('views')
            const consultant_id = localStorage.getItem('consultant_id')
            if (token) {
                commit('SET_USER', {
                    data: {
                        token: token,
                        user: JSON.parse(user),
                        name: name,
                        type: type,
                        email_user: email_user,
                        permisos: JSON.parse(permisos || "[]"),
                        views: JSON.parse(views || "[]"),
                        consultant_id: consultant_id,
                    }
                })
            } else {
                commit('SET_USER', '')
            }

        },
        views({ commit }) {
            commit('SET_VIEWS_LOGIN');
        },
        login({ commit }, payload) {
            commit('SET_USER_LOGIN', payload);
            commit('SET_USER', payload);
        },
        logout({ commit }) {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('name')
            localStorage.removeItem('type')
            localStorage.removeItem('email_user')
            localStorage.removeItem('permisos')
            localStorage.removeItem('views')
            localStorage.removeItem('consultant_id')
            localStorage.removeItem("warehousesOrigenFilter");
            localStorage.removeItem("warehousesDestinoFilter");
            commit('SET_USER', '')
        },
    },
    getters: {
        isActive: state => !!state.token
    }
})