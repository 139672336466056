<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />
  </v-app>

</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'App',

  components: {
    
  },
   methods: {
    ...mapActions(["readUser"]),
  },
  created() {
    this.readUser();
  },

};
</script>