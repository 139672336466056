import axios from 'axios'

const axiosHttps = axios.create({
    //Desarrollo parece
    baseURL: 'https://erp.gpsgoldcar.com/api/'
    //Producciones
    //baseURL: 'http://66.240.223.46:80/api/'
  });

  const axiosDocument = axios.create({
    baseURL: 'https://www.tus-ventas-facil.com/api/v1/'
  });

  const axiosDownload =
  //Desarrollo 
  ''//string vacio, por que URL esta compelto del backend
  //Produccion
  //'http://66.240.223.46:80'
  ;
  

  axiosHttps.interceptors.request.use(
    async (config) =>{
        const token = await localStorage.getItem('token')
        if( token ){
            config.headers['Authorization'] = "Token " + token;
        }
        return config;
    }
)

  export {
    axiosHttps, axiosDocument, axiosDownload
  };